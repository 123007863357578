import { useEffect, useRef } from 'react';

import 'dialog-polyfill/dist/dialog-polyfill.css';

export default function useDialog(
  ref: React.RefObject<HTMLDialogElement | null>,
  isOpen: boolean,
  close: () => void,
  options: {
    autoFocusDialogElement?: boolean;
    displayOnPageLoad?: boolean;
    pageLoadPriority?: number;
    withTransitions?: boolean;
  } = {
    autoFocusDialogElement: true,
    displayOnPageLoad: false,
    pageLoadPriority: undefined,
    withTransitions: false,
  },
) {
  const {
    autoFocusDialogElement = true,
    displayOnPageLoad = false,
    pageLoadPriority,
    withTransitions = false,
  } = options;
  const clickedBackdrop = useRef(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: we don't want to include the ref in the dependency array
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (displayOnPageLoad && 'showModal' in ref.current) {
      return;
    }

    if (isOpen) {
      if (!('showModal' in ref.current)) {
        // 👇 import dynamically because `window` is accessed outside of the function, which throws a server-side-error
        window.dialogPolyfill = window.dialogPolyfill || require('dialog-polyfill').default;
        window.dialogPolyfill.registerDialog(ref.current);
      }

      ref.current.showModal();

      if (withTransitions) {
        ref.current.classList.add('transitioned');
      }

      if (autoFocusDialogElement) {
        ref.current.focus();
      }
    } else if (ref.current.open) {
      if (withTransitions) {
        ref.current.classList.remove('transitioned');
      } else {
        ref.current.close();
      }
    }
  }, [isOpen]);

  return {
    'data-on-page-load': displayOnPageLoad ? '' : undefined,
    'data-priority': displayOnPageLoad ? pageLoadPriority : undefined,
    onCancel: close,
    onClick: (e) => {
      // light dismiss when clicking on the backdrop and outside of the dialog
      if ((e.target as HTMLElement).nodeName === 'DIALOG' && clickedBackdrop.current) {
        clickedBackdrop.current = false;
        close();
      }
    },
    onMouseDown: (e) => {
      if ((e.target as HTMLElement).nodeName === 'DIALOG') {
        // user clicked outside dialog, get ready to close if its released there too
        clickedBackdrop.current = true;
      }
    },
    onTransitionEnd: () => {
      if (!isOpen && ref.current?.open) {
        ref.current.close();
      }
    },
    suppressHydrationWarning: !!displayOnPageLoad,
    tabIndex: autoFocusDialogElement ? -1 : undefined,
  } as React.ComponentPropsWithoutRef<'dialog'>;
}
